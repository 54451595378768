import styled from 'styled-components'
import { css } from '../../styles/VariablesStyles'

export const MainMenuStyles = styled.nav`

	/* global */
	padding: 0 var(--gap);
	a {
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		margin: 0;
		color: var(--color-2--light);
		&:focus {
			outline: 0;
		}
	}
	.menu__secondary {
		svg {
			position: relative;
			top: .35em;
			left: .3em;
			display: inline-block;
			width: .75em;
			height: .75em;
			will-change: transform;
			transition: transform .3s;
		}
	}
	.menu-item-parent {
		&:after {
			content: "";
			border: 0 solid transparent;
			border-width: .5em .25em;
			border-top-color: currentColor;
			transform-origin: .2em .2em;
			transition: transform .3s;
		}
	}
	.menu-main__logo {
		position: relative;
		z-index: 100;
		display: block;
		width: 4.5em;
		margin-top: .5em;
		margin-right: .75em;
		.page-ani-fade--exited &[aria-current="page"] {
			color: hsla(var(--color-2--light-raw), 0.5);
			transition: 0s;
		}
		&:hover,
		&:focus {
			color: hsla(var(--color-2--light-raw), 0.5);
		}
		&:after,
		&:before {
			display: none;
		}
		svg {
			display: block;
			width: 100%;
			fill: currentColor;
		}
	}

	/* desktop */
	@media (min-width: ${css.bp.lgMid + 1}px) {
		display: flex;
		padding-right: 0;
		a {
			&:hover,
			&:focus {
				outline: 0;
				color: var(--color-2--dark);
			}
		}
		.menu {
			display: flex;
			min-height: 3.7778em;
			& > li > a {
				display: flex;
				align-items: center;
				max-width: 5em;
				padding-left: .8em;
				font-weight: bold;
				background-image: linear-gradient(to bottom, var(--color-2--light), hsla(var(--color-2--light-raw),0));
				background-size: 1px 3em;
				background-repeat: no-repeat;
				background-position: 1px center;
				line-height: 1.09;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: -2px;
					bottom: 10px;
					z-index: -1;
					opacity: 0;
					transition: all .4s, background-color .6s;
					background-image: linear-gradient(to top, var(--color-2--light), hsla(var(--color-2--light-raw),0));
				}
			}
			.page-ani-fade--exited & > li > a[aria-current="page"],
			& > li > a:hover,
			& > li > a:focus,
			& > li:hover > a,
			& > li:focus-within > a {
				background-size: 1px 100%;
				color: var(--color-2--dark);
				&:before {
					background-color: var(--color-2--light);
					opacity: 1;
				}
			}
			& > li {
				position: relative;
				display: flex;
				width: 7.25em;
				font-size: var(--font-size-small-px);
			}
			& > li > a:hover + ul,
			& > li > a:focus + ul,
			li:hover ul,
			li:focus-within ul {
				visibility: visible;
				top: calc(100% - 10px);
				opacity: 1;
				transition: all .3s, visibility 0s, top 0s;
				&:before {
					opacity: 1;
				}
				a {
					opacity: 1;
				}
			}
			.menu-item-parent {
				&:hover,
				&:focus,
				&:focus-within,
				&[aria-expanded="true"] {
					&:after {
						transform: rotate(-180deg);
					}
				}
				&:after {
					content: "";
					position: absolute;
					bottom: 1.1em;
					right: 1.25em;
				}
			}
			.menu__secondary {
				overflow: hidden;
				visibility: hidden;
				position: absolute;
				top: -100em;
				z-index: 10;
				min-width: 9.5em;
				background-color: var(--color-2--light);
				opacity: 0;
				transition: all .3s, visibility 0s .3s, top 0s .3s;
				&:before,
				&:after {
					transform: scale(-1) translate(-1px, 1px); /* rotate it */
				}
				li {
					&:first-child {
						a {
							background-image: none;
						}
					}
					&:last-child {
						a {
							padding-bottom: .65em;
						}
					}
				}
				a {
					position: relative;
					display: block;
					width: 100%;
					padding: .35em  0 .1em .75em;
					background-image: linear-gradient(to right, var(--color-2--dark), hsla(var(--color-2--dark-raw),0));
					background-size: calc(100% - .6em) 1px;
					background-repeat: no-repeat;
					background-position: center top;
					font-size: 1.1em;
					font-family: var(--fam-1);
					color: var(--color-2--dark);
					opacity: 0;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: -2px;
						z-index: -1;
						opacity: 0;
						transition: .3s;
						background-image: linear-gradient(to right, var(--color-2--dark), hsla(var(--color-2--dark-raw),0));
					}
					&:hover,
					&:focus {
						color: var(--color-2--light);
						&:before {
							opacity: 1;
							background-color: var(--color-2--dark);
							transition: opacity .3s, background-color 1s;
						}
					}
					svg {
						position: relative;
						display: inline-block;
						width: .75em;
						height: .75em;
						will-change: transform;
						transition: transform .3s;
						stroke: currentColor;
					}
				}
			}
		}
		.menu-main__logo {
			&:before {
				content: "";
				position: absolute;
				top: -.5em;
				left: -.5em;
				right: -.9em;
				bottom: 0;
				z-index: -1;
				opacity: 0;
				transition: all .4s, background-color .6s;
				background-image: linear-gradient(to top, var(--color-2--light), hsla(var(--color-2--light-raw),0));
			}
			&:hover {
				&:before {
					background-color: var(--color-2--light);
					opacity: 1;
				}
			}
		}

	}

	/* mobile */
	@media (max-width: ${css.bp.lgMid}px) {

		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: calc(100% - 3.75em);
		background-color: var(--color-2--dark);
		transition: bottom .3s;
		.menu-main__logo {
			top: 0;
			display: block;
			width: 4em;
			margin-top: .5em;
			margin-right: .75em;
			svg {
				display: block;
				width: 100%;
				fill: currentColor;
			}
		}
		.menu {
			padding-top: 4em;
			text-align: center;
			a {
				&:hover,
				&:focus {
					color: var(--color-light);
				}
			}
			& > li > a {
				display: inline-block;
				padding: .5em 0;
				font-size: 1.35em;
				font-weight: bold;
				opacity: 0;
				transform: translateY(-.35em);
				transition: all 0s .3s, color .3s;
			}
		}
		.menu__secondary {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			max-height: 0;
			transition: max-height .3s ease-out;
			a {
				visibility: hidden;
				display: block;
				padding: .15em 0;
				opacity: 0;
				transition: all 0s .3s, color .3s;
				transform: translateY(-.25em);
			}
			&.submenu-active {
				overflow-y: auto;
				max-height: 22em;
				transition: max-height .3s ease-in;
				a {
					visibility: visible;
					opacity: 1;
					transform: none;
					transition: all .6s, color .3s;
				}
			}
		}
		.menu-item-parent {
			&[aria-expanded="true"] {
				&:after {
					transform: rotate(-180deg);
				}
			}
			&:after {
				position: relative;
				display: inline-block;
				vertical-align: bottom;
				top: .1em;
				left: .35em;
			}
		}

		/* active state */
		.menu-active & {
			bottom: 0;
			.menu {
				& > li > a {
					opacity: 1;
					transform: none;
					transition: all .6s, color .3s;
				}
			}
		}

	}
`
