import React from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem'

const Ul = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`

const Menu = ({ menu, className, linkClassName }) => {
	// create only top-level links (since data contains a flat list)
	const topLevel = menu.filter((menuItem) => {
		return ! menuItem.parentId
	})
	// render
	return (
		<Ul className={['menu', className].join(' ')}>
			{topLevel.map((menuItem, i) => <MenuItem linkClassName={linkClassName} menuItem={menuItem} key={i} i={i} />)}
		</Ul>
	)
}

export default Menu