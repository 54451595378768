// import React, { useState, useContext } from 'react'
// import { TransitionState } from 'gatsby-plugin-transition-link'

// /**
//  * Persistent layout wrapper for page-to-page transitions
//  * 
//  * This must be separate from normal <Layout /> component or Transition Link will nest
//  * dual layouts within each other.
//  * 
//  * @see https://transitionlink.tylerbarnes.ca/docs/installation/#usage-with-gatsby-plugin-layout
//  */
// const LayoutTransitions = ({children}) => {
//   // render
//   return (
//     <TransitionState>
//       {() => <div id="gatsby-plugin-transition-link-container">{children}</div>}
//     </TransitionState>
//   )
// }

// export default LayoutTransitions





import React, { useState, useContext } from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import StickyMenu from './menus/StickyMenu'
import MenuContext, { MenuProvider } from './menus/MenuContext'
import { Helmet } from 'react-helmet'
import { mediaStyles } from '../styles/Media'
import { TransitionState } from 'gatsby-plugin-transition-link'

const Layout = ({ children }) => {

  // establish nav state
  const menuContextInitial = useContext(MenuContext)
  const triggerShowMenu = (should = false) => {
    setMenuState({ ...menuState, showMenu: (true === should ? should : false) })
  }
  const [menuState, setMenuState] = useState({ ...menuContextInitial, triggerShowMenu })

  // render
  return (
    <TransitionState>
      {() => {
        // render
        return (
          <TransitionState>
            {() => (
              <MenuProvider value={ menuState }>
                <GlobalStyles />
                <Helmet>
                  <style>{mediaStyles}</style>
                </Helmet>
                <div className={`gatsby-plugin-transition-link-wrap${menuState.showMenu ? ` menu-active` : ``}`}>
                  <StickyMenu triggerShowMenu={triggerShowMenu} />
                  {children}
                </div>
              </MenuProvider>
            )
          }
          </TransitionState>
        )
      }}
    </TransitionState>
  )
}

export default Layout
