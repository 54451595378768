import { createGlobalStyle } from 'styled-components'

export const css = {
	// fonts
	fontSize: 18,
	fontSizeSmall: 14,

	// layout
	gap: 18,
	gapBaby: 24,
	gapBig: 48,

	// line spacing
	lineGap: 0.35,
	lineGapBig: 1.3333 , // (css.gapBaby/css.fontSize).toFixed(4)
	lineGapBaby: 0.8666, // (big - big*0.35).toFixed(4)

	// breakpoints
	bp: {
		lgMax: 1500,
		lgMid: 1000,
		lgMin: 960,
		mdMax: 800,
		mdMid: 700,
		mdMin: 600,
		smMax: 500,
		smMid: 480,
		smMin: 320,
	}

}

// convert px to em based on font size
const pxToEm = (num, round=4) => {
	return `${(num/css.fontSize).toFixed(round)}em`
}

const Variables = createGlobalStyle`

:root {
	/* p r i m a r y */
	--color-1-raw: 133, 30%, 25%; /* #2c5234 */
	--color-1--light-raw: 135, 37%, 35%;
	/* --color-1--dark: var(--color-1); */

	/* s e c o n d a r y */
	--color-2-raw: 45, 34%, 25%; /* #554a2a */
	--color-2--light-raw: 47, 55%, 59%;/* was 47, 55%, 40%; #9f862e */
	--color-2--dark-raw: 26, 8%, 16%; /* #2d2926 */


	/* d r a b */
	--color-drab: #95a6ab;
	--color-drab--light: #a7abac;
	--color-drab--dark: #505759;

	/* standard colors */
	--color-light-raw: 36, 100%, 98%; /* white-ish */
	--color-dark-raw: 0, 0%, 0%; /* black */

	/* implementation */
	--color-light: hsl(var(--color-light-raw));
	--color-dark: hsl(var(--color-dark-raw));
	--color-1: hsl(var(--color-1-raw));
	--color-1--light: hsl(var(--color-1--light-raw));
	--color-2: hsl(var(--color-2-raw));
	--color-2--light: hsl(var(--color-2--light-raw));
	--color-2--dark: hsl(var(--color-2--dark-raw));

	--color-good: var(--color-1--light);  // not shown in design
	--color-bad: #ff3e21; // not shown in design

	/**
	* Text Styling
	*/
	/* global font size (will add px before use) */
	--font-size: ${css.fontSize};
	--font-size-px: ${css.fontSize}px;
	--font-size-small-px: ${css.fontSizeSmall}px;

	/* font families */
	--fam-1: 'Lato', sans-serif;
	--fam-2: 'Shelby', serif;
	--fam-3: 'Acumin Pro Condensed', condensed;

	/**
	 * Animations
	 */
		--delay: 0s;

	/**
	* Layout
	*/
	/* gaps */
	--gap-size: ${css.gap};
	--gap-px: ${css.gap}px;

	/* gaps in scalable em format */
	--gap: ${css.gap/css.fontSize}em;
	--gap-big: ${css.gapBig/css.fontSize}em;
	--gap-baby: ${css.gapBaby/css.fontSize}em;

	/* gaps in rigid em format */
	--gap-rem: ${css.gap/css.fontSize}rem;
	--gap-big-rem: ${css.gap*4}rem;
	--gap-baby-rem: ${css.gapBaby/css.fontSize}rem;

	/* vertical spacing */
	--gap-mt: ${pxToEm(80)};
	@media (max-width: ${css.bp.mdMax}px){
		--gap-mt: ${pxToEm(50)};
	}
	--gap-pt: ${pxToEm(120)};
	@media (max-width: ${css.bp.mdMax}px){
		--gap-pt: ${pxToEm(70)};
	}
	--gap-pb: ${pxToEm(110)};
	@media (max-width: ${css.bp.mdMax}px){
		--gap-pb: ${pxToEm(70)};
	}
	--gap-mb: ${pxToEm(110)};
	@media (max-width: ${css.bp.mdMax}px){
		--gap-mb: ${pxToEm(60)};
	}

	/* horizontal row widths */
	--row: ${1200 + css.gap * 2}px;
	--row-baby: ${1080 + css.gap * 2}px;
	--row-big: 1500px;

	/* specialty reusable properties */
	--menu-height: calc(3.8em + var(--gap));
	@media (max-width: ${css.bp.mdMid}px){
		--menu-height: calc(6.3em + var(--gap));
	}
	--menu-height-flex: clamp(var(--menu-height), 12vh, 100vh);

}
`

export default Variables
