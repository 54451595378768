import React from 'react'
import { MainMenuStyles } from './MainMenuStyles'
import { MainMenuData } from './MainMenuData'
import Menu from './Menu'
import Anchor from '../Anchor'
import Logo from '../../static/logo.inline.svg'


const MainMenu = ({ className }) => {
	const menuData = MainMenuData()
	const menu = menuData.allWpMenu.nodes[0].menuItems.nodes

	return (
		<MainMenuStyles
			id="menu-main"
			className={`${className}`}
		>
			<Anchor
				href="/"
				aria-label="801 Home Page"
				className="menu-main__logo"
			>
				<Logo />
			</Anchor>
			<Menu menu={menu} />
		</MainMenuStyles>
	)
}

export default MainMenu