import { createMedia } from '@artsy/fresnel'
import { css } from './VariablesStyles'

// increment each value (since fresnel uses mobile first and css.bp doesn't)
const breakpoints = {}
for (const [key, value] of Object.entries(css.bp)) {
  breakpoints[key] = value + 1
}

const QueryBreakpoints = createMedia({ breakpoints })

export const mediaStyles = QueryBreakpoints.createMediaStyle()
export const { Media, MediaContextProvider } = QueryBreakpoints