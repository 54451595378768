import React, { useState } from "react"
import styled from "styled-components"
import Anchor from "../Anchor"
import slugify from "../../utils/slugify"
import { MenuConsumer } from "./MenuContext"

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const MenuItem = ({ menuItem, i, linkClassName }) => {
  // identify if we've submenus
  const submenuClass = menuItem?.childItems?.nodes.length
    ? "menu-item-parent"
    : null

  // handle subnav
  const [submenuState, setSubmenuState] = useState(false)
  const showSubmenu = submenuState => {
    setSubmenuState(submenuState)
  }

  // render
  return (
    <MenuConsumer>
      {context => {

        // handle close of menu when navigating
        const triggerCloseMenu = (should = true) => {
          if (should) {
            // wait for animations to conclude
            setTimeout(() => {
              context.triggerShowMenu()
            }, 200);
          }
        }

        return (
          <li key={i} onClick={() => triggerCloseMenu(!submenuClass)}>
            <Anchor
              href={menuItem.url}
              target={menuItem.target}
              className={[linkClassName, submenuClass].join(" ")}
              {...(submenuClass && {
                onClick: () => showSubmenu(!submenuState),
                "aria-expanded": submenuState,
                "aria-controls": `submenu-${i}-${slugify(menuItem.label)}`,
              })}
            >
              {menuItem.label}
            </Anchor>
            {submenuClass ? (
              <Ul
                id={`submenu-${i}-${slugify(menuItem.label)}`}
                className={`menu__secondary${
                  submenuState ? ` submenu-active` : ``
                }`}
              >
                {menuItem.childItems.nodes.map((subMenuItem, i) => {
                  // create the submenu if it exists
                  return (
                    <li key={i} onClick={triggerCloseMenu}>
                      <Anchor
                        className="button--arrow"
                        href={subMenuItem.url}
                        target={subMenuItem.target}
                        onClick={() => showSubmenu(!submenuState)}
                      >
                        {subMenuItem.label}
                      </Anchor>
                    </li>
                  )
                })}
              </Ul>
            ) : null}
          </li>
        )
      }}
    </MenuConsumer>
  )
}

export default MenuItem
