import { graphql, useStaticQuery } from 'gatsby'

export const MainMenuData = () => {
	return useStaticQuery(graphql`
		{
			allWpMenu(filter: {
				name: {
					eq: "main"
				}
			}){
				nodes {
					name
					menuItems {
						nodes {
							label
							url
							target
							parentId
							childItems {
								nodes {
									label
									url
									target
								}
							}
						}
					}
				}
			}
		}
	`)
}