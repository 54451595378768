import React from 'react'
import { UtilityMenuStyles } from './UtilityMenuStyles'
import { UtilityMenuData } from './UtilityMenuData'
import Menu from './Menu'

const UtilityMenu = ({ className }) => {
	const menuData = UtilityMenuData()
	const menu = menuData.allWpMenu.nodes[0].menuItems.nodes
	return (
		<UtilityMenuStyles className={className}>
			<Menu menu={menu} linkClassName="button" />
		</UtilityMenuStyles>
	)
}

export default UtilityMenu
