import { createGlobalStyle } from 'styled-components'
import pattern from '../static/pattern.svg'

// allow other scripts to use the same values we're using here
export const timings = {
	exit: 0.2, 	// how log to take leaving
	gap: 0.15, 	// how long to wait in between
	entry: 1.3, // how long to take coming back
	exitEase: 'ease-out',
	entryEase: 'cubic-bezier(0.68, 0.03, 0.49, 0.74)',
}

const PageTransitionStyles = createGlobalStyle`
	// pre-styling needed for animations
	body {
		background-color: var(--color-2--dark);
		position: relative;
		&:before {
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			opacity: 0.025;
			background-color: var(--color-2--dark);
			background-image: url(${pattern});
			background-size: 25em;
		}
		h3#happy-hours-title {
			text-align: center;
			margin-bottom: 0;
			margin-top: 50px;
		  }
	}
	.site-content {
		background-color: var(--color-light);
	}

	/**
	 * Page fade animation (default)
	 */
	// exit animations
	.page-ani-fade--exiting, // old page is leaving
	.page-ani-fade--exited, // for a split second it has left
	.page-ani-fade--entering { // initial state for new page
		.page-ani,
		.page-ani-before:before {
			opacity: 0;
			transition: opacity ${timings.exit - 0.01}s ${timings.exitEase}; // finish ani before dom exit
		}
	}
	// entrance animations
	.page-ani-fade--entered { // new page is returning
		.page-ani,
		.page-ani-before:before {
			opacity: 1;
			transition: opacity ${timings.entry}s ${timings.gap}s ${timings.entryEase};
		}
	}

	/**
	 * Page slide animation
	 */
	// exit animations
	.page-ani-slide-left--exiting { // old page is leaving
		.page-ani {
			transform: translateX(100%);
			opacity: 0 !important; // for some reason it otherwise doesn't fade all the way
			transition: .59s linear; // finish ani before dom exit
		}
	}
	/* .page-ani-slide-left--exited, // for a split second it has left */
	.page-ani-slide-left--entering { // initial state for new page
		.page-ani {
			transform: translateX(-100%);
			opacity: 0;
			transition: 0s;
		}
	}
	// entrance animations
	.page-ani-slide-left--entered { // new page is returning
		.page-ani {
			transform: translateX(0%);
			opacity: 1;
			transition: all 2s cubic-bezier(0, 0.12, 0, 0.96), opacity 3s ease-out;
		}
	}

	/**
	 * Home page menu customizations
	 */
	&.page-2 {
		.sticky-menu {
			&:before {
				opacity: 0;
			}
		}
	}

`
export default PageTransitionStyles
