import { graphql, useStaticQuery } from 'gatsby'

export const UtilityMenuData = () => {
	return useStaticQuery(graphql`
	{
		allWpMenu(filter: {
			name: {
				eq: "utility"
			}
		}){
			nodes {
				name
				menuItems {
					nodes {
						label
						url
						target
					}
				}
			}
		}
	}
	`)
}