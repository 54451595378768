import { createGlobalStyle } from 'styled-components'
import { css } from '../styles/VariablesStyles'

// make this global so it can be used by blockquotes, etc. that
// aren't part of this component.
export const LineFrameStyles = createGlobalStyle`
.line-frame {
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 1.75em 2.1em;
	.ani-wrap--hide &.ani,
	&.ani:not(.shown) {
		&:before,
		&:after {
			opacity: 0;
			transform: translate(1em, -1em);
		}
	}
	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		border: 1px solid var(--color-2--light);
	}
	&:before {
		top: ${css.lineGap}em;
		left: 0;
		right: 0;
		bottom: ${css.lineGap}em;
		transition: 1s;
	}
	&:after {
		top: 0;
		left: ${css.lineGap}em;
		right: ${css.lineGap}em;
		bottom: 0;
		transition: 1s .15s;
	}
}
.line-frame--shift-right {
	position: relative;
	margin-left: var(--gap-baby);
	margin-bottom: var(--gap-baby);
	padding: 0;
	&:before {
		top: ${css.lineGapBig}em;
		left: -${css.lineGapBig}em;
		right: ${css.lineGapBaby}em;
		bottom: -${css.lineGapBaby}em;
	}
	&:after {
		top: ${css.lineGapBaby}em;
		left: -${css.lineGapBaby}em;
		right: ${css.lineGapBig}em;
		bottom: -${css.lineGapBig}em;
	}
}
`