import React from 'react'

import Variables from './VariablesStyles'

import BaseStyles from './normalize/BaseStyles'
import EmbeddedContentStyles from './normalize/EmbeddedContentStyles'
import FormsStyles from './normalize/FormsStyles'
import GroupingContentStyles from './normalize/GroupingContentStyles'
import Html5DisplayDefinitionsStyles from './normalize/Html5DisplayDefinitionsStyles'
import LinksStyles from './normalize/LinksStyles'
import ListsStyles from './normalize/ListsStyles'
import TablesStyles from './normalize/TablesStyles'
import TextLevelSemanticsStyles from './normalize/TextLevelSemanticsStyles'

import CommonStyles from './normalize/CommonStyles'

import PageTransitionStyles from './PageTransitionStyles'

import { LineFrameStyles } from '../components/LineFrameStyles'

const GlobalStyles = () => {
  return (
    <>
      <Variables />
      <BaseStyles />
      <EmbeddedContentStyles />
      <FormsStyles />
      <GroupingContentStyles />
      <Html5DisplayDefinitionsStyles />
      <LinksStyles />
      <ListsStyles />
      <TablesStyles />
      <TextLevelSemanticsStyles />
      <CommonStyles />
      <PageTransitionStyles />
      <LineFrameStyles />
    </>
  )
}

export default GlobalStyles
