import styled from 'styled-components'
import { css } from '../../styles/VariablesStyles'

export const UtilityMenuStyles = styled.nav`
	margin-left: auto;
	li {
		display: flex;
		padding-left: .65em;
		@media (max-width: ${css.bp.smMid}px){
			padding-left: .2em;
		}
		&:first-child {
			padding-left: 0;
		}
	}
	a {
		display: flex;
		margin-bottom: 2px;
		padding-left: .72em;
		padding-right: .72em;
		align-items: center;
		justify-content: center;
		@media (max-width: ${css.bp.smMax}px){
			padding-left: .1em;
			padding-right: .1em;
		}
	}
	.menu {
		display: flex;
	}

	/* desktop */
	@media (min-width: ${css.bp.lgMid + 1}px) {
		padding-right: var(--gap);
	}

	/* tablet */
	@media (max-width: ${css.bp.lgMid}px){
		position: relative;
		z-index: 10;
		padding: .2em;
		background-color: hsla(var(--color-2--dark-raw), 0.85);
		max-width: 22em;
		margin: .75em auto 0;
		.menu {
			justify-content: center;
		}
	}

	/* small tablet */
	@media (max-width: ${css.bp.mdMid}px){
		margin-top: 3.75em;
		max-width: none;
		padding: .2em var(--gap);
		li {
			flex: 1;
		}
		a {
			width: 100%;
		}
	}

	/* phone */
	@media (max-width: ${css.bp.smMid}px){
		padding: .15em;
	}

`
