/**
 * Reusable global classes
 */
import { css } from '../VariablesStyles'
import { createGlobalStyle } from 'styled-components'

const CommonStyles = createGlobalStyle`
//  horizontal
.mod--inner,
.mod--inner-pad {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: var(--row);
}
.mod--inner-pad,
.mod--pad-sides {
	padding-left: var(--gap);
	padding-right: var(--gap);
}
.mod--inner-flex {
	display: flex;
	align-items: center;
}
.mod--outer {
	width: 100%;
}

// vertical
.mod--margin-top {
	margin-top: var(--gap-mt);
}
.mod--padding-top {
	padding-top: var(--gap-pt);
}
.mod--padding-bottom {
	padding-bottom: var(--gap-pb);
}
.mod--margin-bottom {
	margin-bottom: var(--gap-mb);
}

/**
* Text
*/
[class*="mod--bg-dark"],
.mod--text-invert {
	&,
	.h1, .h2, .h3, /* .h4 , */ .h5, .h6,
	h1, h2, h3, /* h4 , */ h5, h6,
	/* blockquote, */
	hr,
	.button {
		color: var(--color-light);
	}
	.h4, h4 {
		color: var(--color-2--light);
	}
}
.mod--text-revert {
	&,
	.h1, .h2, .h3, /* .h4 , */ .h5, .h6,
	h1, h2, h3, /* h4 , */ h5, h6,
	blockquote,
	hr,
	.button {
		color: var(--color-dark);
	}
}
.mod--align-center  {
	text-align: center;
}
.mod--align-right  {
	text-align: right;
}
.mod--align-left {
	text-align: left;
}
.mod--align-full  {
	display: block;
}
.mod--nowrap {
	white-space: nowrap;
}

/**
 * Backgrounds and Borders
 */
&:root {
	--bg-color-raw: var(--color-light-raw);
	--bg-color: hsla(var(--bg-color-raw), 1);
	--bg-pos: 50% 50%;
}
.mod--bg-dark-green {
	--bg-color-raw: var(--color-1-raw);
	--bg-color: var(--color-1);
	background-color: var(--color-1);
}
.mod--bg-dark-brown {
	--bg-color-raw: var(--color-2--dark-raw);
	--bg-color: var(--color-2--dark);
	background-color: var(--color-2--dark);
}
.mod--gradient-bg-right {
	background-image: linear-gradient(to bottom, var(--color-2--light), hsla(var(--color-2--light-raw),0));
	background-repeat: no-repeat;
	background-size: 1px 100%;
	background-position: left top;
	&:first-child {
		background-image: none;
	}
}
.mod--gradient-bg-top,
.mod--gradient-bg-bottom {
	background-image: linear-gradient(to right, var(--color-2--light), hsla(var(--color-2--light-raw),0));
	background-repeat: no-repeat;
	background-size: 100% 1px;
	background-position: left top;
}
.mod--gradient-bg-bottom {
	background-position: left bottom;
}
.mod--gradient-borders {
	position: relative;
	&:after {
		content: "";
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		z-index: 10;
		background-image: linear-gradient(to bottom, var(--color-2--light), hsla(var(--color-2--light-raw),0));
		background-size: 1px 100%;
		background-repeat: no-repeat;
		background-position: 50%;
		transition: 3s;
	}
	& > * {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			width: calc(100vw - var(--gap) - var(--gap));
			max-width: calc(var(--row) - var(--gap) - var(--gap));
			height: 1px;
			background-image: linear-gradient(to right, var(--color-2--light), hsla(var(--color-2--light-raw),0));
			transition: opacity 1s, width 2.5s;
		}
	}
	&.mod--cols-2 {
		@media (min-width: ${css.bp.mdMax + 1}px){
			&:after {
				display: block;
			}
			& > * {
				&:nth-child(2n+2) {
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&.mod--cols-3 {
		@media (min-width: ${css.bp.mdMax + 1}px){
			&:after {
				display: block;
				background-image:
					linear-gradient(to bottom, var(--color-2--light), hsla(var(--color-2--light-raw),0)),
					linear-gradient(to bottom, var(--color-2--light), hsla(var(--color-2--light-raw),0));
				background-position: 33.33% top, 66.67% top;
			}
			& > * {
				&:nth-child(3n+3),
				&:nth-child(3n+2) {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	/* animations */
	&,
	& > * {
		&:not(.shown) {
			&:after {
				opacity: 0;
				transition: 0s;
				width: 0;
				height: 0;
			}
		}
	}
}

/**
 * One-offs
 */
.no-hero { // add space under menu if first module should have space above
	& + .site-content .page > *:first-child {
		&.mod--padding-top:not(.mod--margin-top) {
			padding-top: var(--menu-height);
		}
		&.mod--margin-top {
			margin-top: calc(var(--menu-height) - var(--gap));
		}
	}
}
.site-main {
	.is-gradient-fade {
      align-self: center;
      margin-bottom: -5em;
      mask: linear-gradient(
        to top,
        rgba(0, 0, 0, 0) 5em,
        rgba(0, 0, 0, 0.5) 7em,
        rgba(0, 0, 0, 1) 12em
      );
      @media (max-width: ${css.bp.mdMax}px) {
        grid-column: 1;
        grid-row: 1;
        mask: linear-gradient(
          to top,
          rgba(0, 0, 0, 0) 5em,
          rgba(0, 0, 0, 0.2) 7em,
          rgba(0, 0, 0, 0.12) 85%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
	.is-behind {
		@media (max-width: ${css.bp.mdMax}px) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			min-height: 20em;
			margin: 0;
			padding: 0;
			mask: linear-gradient(
				to top,
				rgba(0, 0, 0, 0) 5%,
				rgba(0, 0, 0, 0.2) 50%,
				rgba(0, 0, 0, 0.12) 85%,
				rgba(0, 0, 0, 0) 100%
			);
			.gatsby-image-wrapper {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}
}
.mod--limit-paragraph-widths {
	p[style*="center"] {
		max-width: 23em;
		margin-left: auto;
		margin-right: auto;
	}
}
.mod--pull-img-up {
	img {
		display: block;
		margin: -2em auto;
		width: 30em;
		@media (max-width: ${css.bp.lgMid}px){
			margin: -1em auto;
		}
	}
}

/**
 * Shame (╯°□°)╯︵ ┻━┻
 */
.gatsby-image-wrapper {
	&,
	& * {
		background-color: transparent !important; // fixes safari issues
	}
}
`

export default CommonStyles
