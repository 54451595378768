import React from 'react'
import styled from 'styled-components'
import { css } from '../../styles/VariablesStyles'
import TriggerMenu from './TriggerMenu'
import MainMenu from './MainMenu'
import UtilityMenu from './UtilityMenu'
import { MenuConsumer } from './MenuContext'

const StickyMenuStyles = styled.div`

	/* global */
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;

	/* mobile */
	@media (max-width: ${css.bp.lgMid}px) {
		.site-header__nav {
			flex-wrap: wrap;
		}
		.trigger-menu {
			order: 1;
		}
		.main-menu {
			order: 3;
			width: 100%;
		}
		.util-menu {
			order: 2;
			width: 100%;
		}
	}

	/* backdrop */
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background-image: linear-gradient(
			to right,
			hsla(var(--color-2--dark-raw), 98%) 50%,
			hsla(var(--color-2--dark-raw), 35%)
		);
		border-bottom: 1px solid hsla(var(--color-2--dark-raw), 25%);
	}
`

const StickyMenu = () => {
	return (
		<MenuConsumer>
			{ (context) => {
				return (
					<StickyMenuStyles className="sticky-menu page-ani-before">
						<div className="site-header__nav mod--inner mod--inner-flex">
							<TriggerMenu
								className="trigger-menu"
								triggerShowMenu={context.triggerShowMenu}
								showMenu={context.showMenu}
							/>
							<MainMenu
								className="main-menu"
								triggerShowMenu={context.triggerShowMenu}
							/>
							<UtilityMenu className="util-menu" />
						</div>
					</StickyMenuStyles>
				)
			} }
		</MenuConsumer>
	)
}

export default StickyMenu
