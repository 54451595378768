import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { absToRel } from '../utils/links'
import Arrow from '../static/arrow.inline.svg'
import { timings } from '../styles/PageTransitionStyles'
import { HashConsumer } from './HashContext'
import OpenTableWidget from './OpenTableWidget'

const ActualAnchor = (props) => {
	const href = props.href ? absToRel(props.href) : ''
	const hasArrow = props.className && (
		props.className.includes('button--big') ||
		props.className.includes('button--arrow')
	)
	const isAbs = href?.startsWith('/') && !href?.startsWith('//')
	const isHash = href?.startsWith('#')
	const hasHash = href?.includes('#') // NOTE: workaround for https://github.com/TylerBarnes/gatsby-plugin-transition-link/issues/209
	if (isAbs && ! hasHash && ! props.target) {
		return (
			<TransitionLink
				{ ...props }
				to={ href }
				exit={{
					length: timings.exit, // exit at specified speed
				}}
				entry={{
					delay: timings.exit, // delay while waiting for page exit
					length: 0, // trigger entry animation immediately after delay
				}}
			>
				{ props.children }
				{hasArrow && <Arrow />}
			</TransitionLink>
		)
	} else if (href) {
		const isOTWidget = href.includes('opentable.com/widget/reservation')
		const isMultiOTWidget = isOTWidget && href.includes('type=multi')
		const classes = [
			props.className,
			(isOTWidget ? `button--ot` : ``),
			(isMultiOTWidget ? `button--ot-multi` : ``),
		];
		return (
			<a
				href={ href }
				rel={'_blank' === props.target ? 'noreferrer' : undefined}
				{ ...props }
				className={classes.join(' ')}
			>
				{ props.children }
				{hasArrow && <Arrow className={isHash ? 'hash' : ''} />}
				{isOTWidget && <OpenTableWidget src={href} />}
			</a>
		)
	} else {
		return (
			<span
				{ ...props }
				aria-disabled="true"
			>
				{ props.children }
				{hasArrow && <Arrow />}
			</span>
		)
	}
}

const Anchor = (props) => {
	// update context if this link has an anchor link
	const urlHash = props && props.href ? props.href.split('#')[1] : false
	if(urlHash){
		return (
			<HashConsumer>
				{(context) => {
					return (
						<ActualAnchor
							{...props}
							onClick={(e) => {
								context.updateHash(urlHash, e)
							}}
						/>
					)
				}}
			</HashConsumer>
		)
	// just show the link normally if it's not an anchor
	} else {
		return <ActualAnchor {...props} />
	}
}

export default Anchor
