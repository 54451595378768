import './src/static/fonts/fonts.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/400-italic.css'
import '@fontsource/lato/900.css'

import React from 'react'
import { MediaContextProvider } from './src/styles/Media'

export const wrapRootElement = ({ element }) => (
  <MediaContextProvider>{element}</MediaContextProvider>
)
