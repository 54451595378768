import React from 'react'
import styled from 'styled-components'
import LockScroll from '../../styles/LockScroll'
import { css } from '../../styles/VariablesStyles'

const TriggerMenuStyles = styled.button`

	/* desktop */
	@media (min-width: ${css.bp.lgMid + 1}px) {
		display: none;
	}

	/* mobile */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	overflow: hidden;
	display: block;
	position: absolute;
	top: 1em;
	right: var(--gap);
	z-index: 10;
	width: 8em;
	height: 2.5em;
	border-radius: 2em;
	border: 0;
	background-color: var(--color-2--light);
	text-align: left;
	color: var(--color-2--dark);
	font-size: .79em;
	font-weight: bold;
	&:before,
	&:after {
		content: "";
		position: absolute;
		opacity: 0;
		transition: .3s;
	}
	&:before {
		border: 0 solid transparent;
		border-width: .5em .25em;
		border-top-color: currentColor;
		right: .8em;
		top: 1.01em;
	}
	&:after {
		top: .72em;
		right: 1.7em;
		width: 1.25em;
		height: 1.25em;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2l13.1 13M14.1 2l-13 13' stroke='%232d2926' stroke-width='3'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}
	&:focus {
		outline: 0;
		animation: dim .6s;
	}
	@keyframes dim {
		25% {
			opacity: 0.65;
		}
	}
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		white-space: nowrap;
		opacity: 0;
		transition: .3s;
		&:first-child {
			transform: translateX(-.4em) scale(0.5);
		}
		&:last-child {
			transform: translateX(-.65em) scale(0.5);
		}
	}
	&[aria-expanded="false"] {
		&:before {
			opacity: 1;
		}
		&:after {
			transform: translateX(1em) scale(.5) rotate(15deg);
		}
		span:first-child {
			opacity: 1;
			transform: translateX(-.4em);
		}
	}
	&[aria-expanded="true"] {
		&:before {
			transform: translateX(-1em);
		}
		&:after {
			opacity: 1;
		}
		span:last-child {
			opacity: 1;
			transform: translateX(-.65em);
		}
	}

`

const TriggerMenu = ({ className, triggerShowMenu, showMenu }) => {
	return (
		<TriggerMenuStyles
			className={className}
			onClick={ () => triggerShowMenu(!showMenu)}
			aria-controls="menu-main"
			aria-expanded={ showMenu }
		>
			{showMenu && <LockScroll />}
			<span aria-hidden={ showMenu }>Main Menu</span>
			<span aria-hidden={ !showMenu }>Close</span>
		</TriggerMenuStyles>
	)
}

export default TriggerMenu
