import React from 'react'

export const getHash = () => {
	return window.location?.hash.split('#')[1]
}
export const setHash = (hash) => {
	if(hash){
		window.history.replaceState(null, '', `#${hash}`)
	} else {
		window.history.replaceState(null, '', window.location.pathname + window.location.search)
	}
}

const HashContext = React.createContext({
	hash: (typeof window !== 'undefined' ? getHash() : false),
})

export const HashProvider = HashContext.Provider
export const HashConsumer = HashContext.Consumer
export default HashContext
